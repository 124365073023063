import { Select, MenuItem } from "@mui/material";
import { CouponData, CouponType } from "../../services/admin/coupon";

interface CouponFiltersProps {
  selectedOrg: string;
  sortBy: string;
  sortOrder: "asc" | "desc";
  organizations: string[];
  data: CouponData[];
  onOrgChange: (value: string) => void;
  onSortByChange: (value: string) => void;
  onSortOrderChange: (value: "asc" | "desc") => void;
  onClearFilters: () => void;
  showClearFilters: boolean;
  selectedType: string;
  onTypeChange: (type: string) => void;
}

const CouponFilters = ({
  selectedOrg,
  sortBy,
  sortOrder,
  organizations,
  data,
  onOrgChange,
  onSortByChange,
  onSortOrderChange,
  onClearFilters,
  showClearFilters,
  selectedType,
  onTypeChange,
}: CouponFiltersProps) => {
  return (
    <div className="filter_sort">
      <form className="form">
        <div className="form_control">
          <label>Filter by:</label>
          <Select
            fullWidth
            value={selectedOrg}
            onChange={(e) => onOrgChange(e.target.value)}
          >
            <MenuItem value="all">All Organizations</MenuItem>
            {organizations.map((orgId) => (
              <MenuItem key={orgId} value={orgId}>
                {data.find(item => item.assignedTo[0]._id === orgId)?.companyName || orgId}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="form_control">
          <label>Type:</label>
          <Select
            fullWidth
            value={selectedType}
            onChange={(e) => onTypeChange(e.target.value)}
          >
            <MenuItem value="all">All Types</MenuItem>
            <MenuItem value={CouponType.DIRECT}>Direct</MenuItem>
            <MenuItem value={CouponType.INDIRECT}>Indirect</MenuItem>
          </Select>
        </div>
        <div className="form_control">
          <label>Sort By:</label>
          <Select
            fullWidth
            value={sortBy}
            onChange={(e) => onSortByChange(e.target.value)}
          >
            <MenuItem value="price">Price</MenuItem>
            <MenuItem value="quantity">Quantity</MenuItem>
          </Select>
          <Select
            fullWidth
            value={sortOrder}
            onChange={(e) => onSortOrderChange(e.target.value as "asc" | "desc")}
          >
            <MenuItem value="asc">Ascending</MenuItem>
            <MenuItem value="desc">Descending</MenuItem>
          </Select>
        </div>
        {showClearFilters && (
          <p
            className="btn_link c_danger"
            onClick={onClearFilters}
          >
            Clear filter
          </p>
        )}
      </form>
    </div>
  );
};

export default CouponFilters;