/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import Web3 from "web3";

import { WalletTransaction, WalletTransactionType, WalletType } from "../../../services/admin/wallet";
import { Loader, STORAGE_KEYS, errorToast, getFromStorage, successToast } from "../../../helpers";
import { useLazyGetMyWalletQuery } from "../../../services/main/setting";
import SitePagination from "../../../components/sitePagination";
import { MyWallet } from "../../../types/General";
import { useRedeemCouponMutation } from "../../../services/admin/coupon";

const Wallet = () => {
  const walletAddress = getFromStorage(STORAGE_KEYS.WalletAddress);
  const [myWallet] = useLazyGetMyWalletQuery();
  const [isLoading, setIsLoading] = useState(false);
  const [walletData, setWalletData] = useState<MyWallet>();
  const [walletBalance, setWalletBalance] = useState<any>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);
  const [transactionType, setTransactionType] = useState<WalletTransactionType | 'ALL'>('ALL');
  const [selectedWalletType, setSelectedWalletType] = useState<WalletType>('IMPACT2EARN');
  const [couponCode, setCouponCode] = useState<string>('');
  const [isRedeemDisabled, setIsRedeemDisabled] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const MAX_ATTEMPTS = 3;
  const COOLDOWN_PERIOD = 5 * 60 * 1000;

  const userWallets = walletData?.userWallets?.wallets;
  const i2eWallet = userWallets?.find(wallet => wallet.type === 'IMPACT2EARN');
  const i2eWalletId = i2eWallet?._id;
  const carbonWallet = userWallets?.find(wallet => wallet.type === 'CARBONCREDIT');
  const carbonWalletId = carbonWallet?._id;

  const [redeemCoupon] = useRedeemCouponMutation();

  
  const handleCouponCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCouponCode(e.target.value);
  };
  
  const handleCouponCodeSubmit = async () => {
    try {
      if (isRedeemDisabled) {
        errorToast('Please wait before trying again');
        return;
      }

      if (!couponCode.trim()) {
        errorToast('Please enter a coupon code');
        return;
      }

      setAttemptCount(prev => prev + 1);

      if (attemptCount + 1 >= MAX_ATTEMPTS) {
        setIsRedeemDisabled(true);
        setTimeout(() => {
          setIsRedeemDisabled(false);
          setAttemptCount(0);
        }, COOLDOWN_PERIOD);
      }

      const result = await redeemCoupon(couponCode).unwrap();

      if (result?.statusCode === 200) {
        successToast(result.message);
        setCouponCode('');
        setAttemptCount(0);
        getMyWallet();
      } else {
        errorToast(result?.message || 'Failed to redeem coupon');
      }
    } catch (error: any) {
      errorToast(error?.data?.message || 'Failed to redeem coupon');
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  
  const getMyWallet = async () => {
    setIsLoading(true);
    try {
      const result = await myWallet({}).unwrap();
      if (result?.statusCode === 200) {
        setWalletData(result?.data || []);
        if (result?.data?.entries?.walletTransactions) {
          setTotalPages(Math.ceil(result.data.entries.walletTransactions.length / itemsPerPage));
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  
  const getWalletBalance = async () => {
    const web3 = new Web3(window.ethereum);
    if (walletAddress) {
      const balance = await web3.eth.getBalance(walletAddress);
      setWalletBalance(balance);
    }
  };
  
  useEffect(() => {
    getMyWallet();
  }, []);

  useEffect(() => {
    getWalletBalance();
  }, [walletAddress]);

  const getFilteredTransactions = () => {
    if (!walletData?.entries?.walletTransactions) return [];
    
    return walletData.entries.walletTransactions.filter(transaction => {
      const walletTypeMatch = transaction.walletId === (selectedWalletType === 'IMPACT2EARN' ? i2eWalletId : carbonWalletId);
      
      if (transactionType === 'ALL') {
        return walletTypeMatch;
      }
      
      return transaction.type === transactionType && walletTypeMatch;
    });
  };
  const getPaginatedTransactions = () => {
    const filteredTransactions = getFilteredTransactions();
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredTransactions.slice(startIndex, endIndex);
  };

  useEffect(() => {
    setTotalPages(Math.ceil(getFilteredTransactions().length / itemsPerPage));
  }, [getFilteredTransactions]);

  const paginatedTransactions = getPaginatedTransactions();

  useEffect(() => {
    setPage(1);
  }, [transactionType, selectedWalletType]);

  return (
    <>
      <Loader isLoad={isLoading} />
      <div className="wallet_header_container">
        <div className="balance_section">
          <h4>Balance:</h4>
          <div className="redeem_container">
            <input 
              type="text" 
              value={couponCode} 
              onChange={handleCouponCodeChange} 
              placeholder="Enter coupon code"
              className="coupon_input"
            />
            <button 
              onClick={handleCouponCodeSubmit} 
              className={`redeem_button ${isRedeemDisabled ? 'disabled' : ''}`}
              disabled={isRedeemDisabled}
            >
              {isRedeemDisabled 
                ? `Wait ${Math.ceil(COOLDOWN_PERIOD/1000/60)} minutes` 
                : 'Redeem'}
            </button>
          </div>
        </div>
      </div>
      <ul className="wallet_listing hd_3">
        <li>
          <div className="inner">
            <div
              className={`d_flex clickable ${selectedWalletType === 'IMPACT2EARN' ? 'active' : ''}`}
              onClick={() => setSelectedWalletType('IMPACT2EARN')}
            >
              <p>i2e Points</p>
              <p>{walletData?.I2E_Balance.toLocaleString()}</p>
            </div>
            <div
              className={`d_flex clickable ${selectedWalletType === 'CARBONCREDIT' ? 'active' : ''}`}
              onClick={() => setSelectedWalletType('CARBONCREDIT')}
            >
              <p>Carbon Points</p>
              <p>{walletData?.carbonCredits.toLocaleString()}</p>
            </div>
          </div>
        </li>
      </ul>
      <TableContainer className="table_container">
        <div className="wallet_header">
          <h4 className="wallet_history">Transaction History</h4>
          <div className="filter_buttons">
            <button 
              className={`filter_btn ${transactionType === 'ALL' ? 'active' : ''}`}
              onClick={() => setTransactionType('ALL')}
            >
              All
            </button>
            <button 
              className={`filter_btn ${transactionType === 'CREDIT' ? 'active' : ''}`}
              onClick={() => setTransactionType('CREDIT')}
            >
              Credit
            </button>
            <button 
              className={`filter_btn ${transactionType === 'DEBIT' ? 'active' : ''}`}
              onClick={() => setTransactionType('DEBIT')}
            >
              Debit
            </button>
          </div>
        </div>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          className="table"
        >
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  Value
                </TableCell>
                <TableCell align="center">
                  Type
                </TableCell>
                <TableCell align="center">
                  Status
                </TableCell>
                <TableCell align="center">
                  Date
                </TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
            {(paginatedTransactions.length > 0) ? (
              paginatedTransactions.map((item: WalletTransaction) => (
                <TableRow key={item.id}>
                  <TableCell align="center">{item.value}</TableCell>
                  <TableCell align="center">{item.type}</TableCell>
                  <TableCell align="center">{item.status}</TableCell>
                  <TableCell align="center">
                    {
                      new Date(item.createdAt).toLocaleString('en-US', { 
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                      })
                    }
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={9}>No data found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {walletData?.entries?.walletTransactions?.length ? (
        <SitePagination
          module={walletData.entries.walletTransactions}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          setPage={setPage}
        />
      ) : undefined}
    </>
  );
};

export default Wallet;
