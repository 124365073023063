import { Tooltip } from "@mui/material";
import React, { useState } from "react";

export function WebNotSupport() {
  const [toolTipMessage, setToolTipMessage] = useState("Copy link.");

  const copyToClipBoard = () => {
    const textToCopy = "https://www.impact2earn.ae/";

    // Create a temporary input element
    const input = document.createElement("input");
    input.setAttribute("value", textToCopy);
    document.body.appendChild(input);
    input.select();

    // Execute the copy command
    const success = document.execCommand("copy");

    // Remove the input element from the DOM
    document.body.removeChild(input);

    // Set tool tip message based on copy success
    if (success) {
      setToolTipMessage("Copied to clipboard.");
    } else {
      setToolTipMessage("Failed to copy.");
    }

    // Reset tool tip message after 2 seconds
    setTimeout(() => {
      setToolTipMessage("Copy link.");
    }, 2000);
  };

  return (
    <section className="mtmsk_ntsprt">
      <div className="s_hd text_center">
        <h2>This Browser doesn't support metamask connectivity</h2>
        <p>
          Please copy this link by clicking on it{" "}
          {/* <Tooltip title={toolTipMessage}> */}
          <u>
            <span style={{ cursor: "pointer" }}>
              https://www.impact2earn.ae/
            </span>
          </u>
          {/* </Tooltip> */} and open metamask browser
        </p>
        <button
          className="btn btn_primary"
          onClick={(e) => {
            e.preventDefault();
            window.open(
              `https://metamask.app.link/dapp/https://${window.location.host}/`
            );
          }}
        >
          click here to redirect to MetaMask
        </button>
      </div>
    </section>
  );
}
