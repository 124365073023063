/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";

import Coupons from "../../components/coupons/coupons";
import Layout from "../../layout";

const CouponsPage = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <section className="site_pagination uht_spc">
        <div className="conta_iner">
          <ul>
            <li>
              <a href="javascript:void(0)" onClick={() => navigate("/")}>
                Home
              </a>
            </li>
            <li>Coupons</li>
          </ul>
          <h1 style={{marginBottom: '30px'}}>Buy Coupons</h1>
          <Coupons />
        </div>
      </section>
    </Layout>
  );
};

export default CouponsPage;
