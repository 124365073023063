/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Modal, Button, Menu, Box, useMediaQuery } from "@mui/material";
import MetamaskModalContent from "../../components/metamastModal";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import useAuth from "../../hooks/useAuth";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from "../../helpers";
import {
  getMetaMaskAmount,
  metaMaskAddress,
  setCredentials,
} from "../../reducers/authSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import {
  useLazyGetUserQuery,
  usePostLogoutMutation,
} from "../../services/auth";
import LogoutModal from "../../components/modals/LogoutModal";
import {
  becomePartnerLink,
  becomeVendorLink,
  jobOpportunityLink,
} from "../../helpers/constants/urls";
import { getCms } from "../../reducers/generalSlice";
import { WebNotSupport } from "../../pages/web3NotSupport";
import { useLazyGetMyWalletQuery } from "../../services/main/setting";
import { MyWallet } from "../../types/General";

declare global {
  interface Window {
    ethereum?: any;
  }
}

const Header = () => {
  const navigate = useNavigate();
  const user = useAuth();
  const dispatch = useAppDispatch();
  const [getUser] = useLazyGetUserQuery();
  const [logoutMethod] = usePostLogoutMutation();
  const walletAddress = useAppSelector(getMetaMaskAmount);
  const cmsData = useAppSelector(getCms);
  const [myWallet] = useLazyGetMyWalletQuery();
  const [walletData, setWalletData] = useState<MyWallet>();

  const handleWalletClick = () => {
    setToStorage(STORAGE_KEYS.activeTab, "8");
    setPointsAnchorEl(null);
    navigate('/account');
  };

  const getMyWallet = async () => {
    try {
      const result = await myWallet({}).unwrap();
      if (result?.statusCode === 200) {
        setWalletData(result?.data || []);
      }
    } catch (error) {
      console.error('Error fetching wallet data:', error);
    }
  };

  useEffect(() => {
    getMyWallet();
  }, [user]);

  useEffect(() => {
    const handleWalletUpdate = () => {
      getMyWallet();
    };

    window.addEventListener('WALLET_UPDATED', handleWalletUpdate);

    return () => {
      window.removeEventListener('WALLET_UPDATED', handleWalletUpdate);
    };
  }, []);

  const isMobile = useMediaQuery("(max-width:600px)");

  const [openLogout, setOpenLogout] = useState<boolean>(false);
  const [scroll, setScroll] = useState(false);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const [openModal, setModalOpen] = React.useState(false);
  const [copied, setCopied] = useState(false);
  const [isMobileModal, setIsMobileModal] = useState(false);
  const [pointsAnchorEl, setPointsAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);

  const connectWallet = async () => {
    if (isMobile && typeof window.ethereum === "undefined") {
      setIsMobileModal(true);
    } else {
      if (window.ethereum) {
        try {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          if (accounts.length) {
            setToStorage(STORAGE_KEYS.WalletAddress, accounts[0]);
            dispatch(
              metaMaskAddress({
                walletAddress: accounts?.[0] || null,
              })
            );
          }
        } catch (err) {
          console.log(err, "kk");
        }
      } else {
        window.open("https://metamask.io/download/", "_blank");
      }
    }
  };

  const copyToClipboard = async (address: string) => {
    try {
      await navigator.clipboard.writeText(address);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    } catch (error) {
      console.error("Error copying to clipboard:", error);
    }
  };
  const handleModalClose = () => setModalOpen(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleLogout = async () => {
    try {
      const res = await logoutMethod({}).unwrap();
      if (res?.statusCode === 200) {
        navigate("/signin", { replace: true });
        removeFromStorage(STORAGE_KEYS.token);
        removeFromStorage(STORAGE_KEYS.tempToken);
        removeFromStorage(STORAGE_KEYS.WalletAddress);
        dispatch(
          setCredentials({
            user: null,
            token: null,
          })
        );
        dispatch(
          metaMaskAddress({
            walletAddress: null,
          })
        );
      } else {
        navigate("/signin", { replace: true });
        removeFromStorage(STORAGE_KEYS.token);
        removeFromStorage(STORAGE_KEYS.tempToken);
        dispatch(
          setCredentials({
            user: null,
            token: null,
          })
        );
      }
    } catch (error: any) {
    }
  };

  const getUserDetails = async () => {
    const token = await getFromStorage(STORAGE_KEYS.token);

    if (token) {
      try {
        const result = await getUser({}).unwrap();
        if (result?.statusCode === 200 && token) {
          dispatch(
            setCredentials({
              user: result?.data,
              token: token,
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const userData = getFromStorage(STORAGE_KEYS.credentials);

    if (token) {
      dispatch(
        setCredentials({
          token: `${token}`,
          user: JSON.parse(`${userData}`),
        })
      );
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);

    if (window.ethereum && user) {
      try {
        window.ethereum.on("accountsChanged", (accounts: any) => {
          console.log("accounts:12 ", accounts, user?.walletAddress);
          if (accounts?.length && user?.walletAddress) {
            dispatch(
              metaMaskAddress({
                walletAddress: accounts?.[0] || null,
              })
            );
            if (token && accounts[0] !== user?.walletAddress && user) {
              console.log("not match");
              errorToast(
                `The account connected doesn't belongs to this profile`
              );
            }
          }
        });
      } catch (err) {
        console.log(err, "kk");
      }
    }
  }, [user]);

  const scrollToTop = () => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "auto" });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  useEffect(() => {
    scrollToTop();
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  window.onload = (event) => {
    isConnected();
  };

  async function isConnected() {
    if (window.ethereum) {
      const token = getFromStorage(STORAGE_KEYS.token);
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
      console.log(accounts, ">>>>>>accounts");
      if (accounts?.length) {
        dispatch(
          metaMaskAddress({
            walletAddress: accounts?.[0] || null,
          })
        );
      } else {
        if (token) {
        } else {
          removeFromStorage(STORAGE_KEYS.WalletAddress);
        }
      }
    }
  }

  const handleNotifClick = async () => {
    setToStorage(STORAGE_KEYS.activeTab, "11");
    setToStorage(STORAGE_KEYS.activeProfileTab, "3");
    navigate("/account");
  };

  return (
    <>
      <header className={scroll ? "site_header scrolled" : "site_header"}>
        <div className="top_header">
          <div className="conta_iner">
            <div className="d_flex">
              <p>Make waste not wasted. Give waste. Get rewards</p>
              <ul className="social_icons">
                <li>
                  <a href="https://t.me/impact_to_earn" target="_blank">
                    <img src="/images/telegram_icon.svg" alt="Telegram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/bantgo/"
                    target="_blank"
                  >
                    <img src="/images/linkedin_icon.svg" alt="Linkedin" />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/BANTgo" target="_blank">
                    <img src="/images/facebook_icon.svg" alt="Facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/ban_tgo" target="_blank">
                    <img src="/images/twitter_icon.svg" alt="Twitter" />
                  </a>
                </li>
              </ul>
              <button
                className="btn btn_primary"
                // onClick={() => navigate("/become-a-vendor")}
                onClick={() => {
                  if (window) {
                    window.open(becomeVendorLink, "_self");
                  }
                }}
              >
                Become a Vendor
              </button>
            </div>
          </div>
        </div>
      </header>
      <div className={`main_header ${isMenuOpen ? "open" : ""}`}>
        <div className="conta_iner">
          <nav className="d_flex">
            <a
              // href="#"
              style={{cursor:'pointer'}}
              className={`site_hamburgur ${isMenuOpen ? "active" : ""}`}
              onClick={toggleMenu}
            >
              {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
            </a>
            <a
              className="site_logo"
              // href="#"
              style={{cursor:'pointer'}}
              onClick={() => {
                scrollToTop();
                navigate("/", { replace: true });
              }}
            >
              <figure>
                <img src="/images/impact2earn_logo.png" alt="Logo" />
              </figure>
            </a>
            <ul className={`site_menu ${isMenuOpen ? "open" : ""}`}>
              <li>
                <a
                  // href="#"
                  style={{cursor:'pointer'}}
                  onClick={() => {
                    scrollToTop();
                    navigate("/", { replace: true });
                  }}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  // href="#"
                  style={{cursor:'pointer'}}
                  onClick={() => navigate("/platform-nfts")}
                >
                  Explore
                </a>
              </li>
              <li>
                <a
                  style={{cursor:'pointer'}}
                  onClick={() => navigate("/coupons")}
                >
                  Coupons
                </a>
              </li>
              <li>
                <a
                  // href="#"
                  style={{cursor:'pointer'}}
                  onClick={() =>
                    navigate("/marketplace-vendors", { state: "vendor" })
                  }
                >
                  Vendors
                </a>
              </li>
              <li>
                <a
                  // href="#"
                  style={{cursor:'pointer'}}
                  // onClick={() => navigate("/our-partners")}
                  onClick={() =>
                    navigate("/marketplace-vendors", { state: "partner" })
                  }
                >
                  Partners
                </a>
              </li>
              <li>
                <Button
                  // href="#"
                  style={{cursor:'pointer'}}
                  variant="text"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  Help Support <ArrowDropDownIcon />
                </Button>
                <Menu
                  id="basic-menu"
                  className="menu_dropdown"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <li>
                    <a
                      style={{cursor:'pointer'}}
                      onClick={() => navigate("/faq")}
                    >
                      FAQ
                    </a>
                  </li>
                  <li>
                    <a
                      // href="#"
                      style={{cursor:'pointer'}}
                      onClick={() => navigate("/privacy-policy")}
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      // href="#"
                      style={{cursor:'pointer'}}
                      onClick={() => navigate("/contact-us")}
                    >
                      Contact Us
                    </a>
                  </li>
                </Menu>
              </li>
              <li>
                <Button
                  // href="#"
                  style={{cursor:'pointer'}}
                  variant="text"
                  aria-controls={open1 ? "basic-menu1" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open1 ? "true" : undefined}
                  onClick={handleClick1}
                >
                  Join Us <ArrowDropDownIcon />
                </Button>
                <Menu
                  id="basic-menu1"
                  className="menu_dropdown"
                  anchorEl={anchorEl1}
                  open={open1}
                  onClose={handleClose1}
                  MenuListProps={{
                    "aria-labelledby": "basic-button1",
                  }}
                >
                  <li>
                    <a
                      href={`${becomeVendorLink}`}
                      target="_self"
                    >
                      Become Vendor
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${becomePartnerLink}`}
                      target="_self"
                    >
                      Become Partner
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${jobOpportunityLink}`}
                      target="_self"
                    >
                      Job Opportunities
                    </a>
                  </li>
                </Menu>
              </li>
            </ul>
            <div className="site_action">
              {user ? (
                <div className="site_noti">
                  <div className="wallet-points-dropdown">
                    <Button
                      aria-controls="points-menu"
                      aria-haspopup="true"
                      onClick={(e) => setPointsAnchorEl(e.currentTarget)}
                      className="points-dropdown-button"
                    >
                      <div className="wallet-points" data-points-length={walletData?.I2E_Balance?.toString().length}>
                        <span className="points-value">{walletData?.I2E_Balance || 0}</span>
                        <span className="points-label">PTS</span>
                      </div>
                    </Button>
                    <Menu
                      id="points-menu"
                      anchorEl={pointsAnchorEl}
                      keepMounted
                      open={Boolean(pointsAnchorEl)}
                      onClose={() => setPointsAnchorEl(null)}
                      className="points-dropdown-menu"
                    >
                      <div className="points-menu-content">
                        <div 
                          className="points-menu-item"
                          onClick={() => handleWalletClick()}
                        >
                          <span>I2E Points:</span>
                          <span className="points-value">
                            {walletData?.I2E_Balance || 0}
                          </span>
                        </div>
                        <div 
                          className="points-menu-item"
                          onClick={() => handleWalletClick()}
                        >
                          <span>Carbon Credits:</span>
                          <span className="points-value">{walletData?.carbonCredits || 0}</span>
                        </div>
                      </div>
                    </Menu>
                  </div>
                  <a
                    onClick={handleNotifClick}
                    style={{cursor:'pointer'}}
                    className="btn_icon"
                  >
                    <NotificationsActiveIcon />
                  </a>
                </div>
              ) : undefined}
              {user ? (
                <>
                  <div className="profile_btn">
                    <Button
                      href="#"
                      variant="text"
                      aria-controls={open2 ? "basic-menu2" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open2 ? "true" : undefined}
                      onClick={handleClick2}
                    >
                      <figure>
                        <img
                          src={
                            user?.image || "/images/placeholder_user_icon.png"
                          }
                          alt="Image"
                        />
                      </figure>
                      <span>{user?.name || ""}</span> <ArrowDropDownIcon />
                    </Button>
                    <Menu
                      id="basic-menu2"
                      className="menu_dropdown menu_dropdownUser"
                      anchorEl={anchorEl2}
                      open={open2}
                      onClose={handleClose2}
                      MenuListProps={{
                        "aria-labelledby": "basic-button2",
                      }}
                    >
                      <li>
                        <a
                          // href="#"
                          style={{cursor:'pointer'}}
                          onClick={() => {
                            setToStorage(STORAGE_KEYS.activeTab, "1");
                            navigate("/account");
                          }}
                        >
                          My Account
                        </a>
                      </li>
                      <li>
                        <a
                          style={{cursor:'pointer'}}
                          onClick={() => setOpenLogout(true)}
                        >
                          Logout
                        </a>
                      </li>
                    </Menu>
                  </div>
                  {cmsData?.isWeb3Enabled ? (
                    <>
                      {user && !walletAddress ? (
                        <Button onClick={connectWallet} className="btn_icon ">
                          <img src="/images/metamask_icon.svg" alt="" />
                        </Button>
                      ) : walletAddress ? (
                        <div className="link_box">
                          <p onClick={() => copyToClipboard(walletAddress)}>
                            <span>
                              {walletAddress?.slice(0, 6) +
                                "..." +
                                walletAddress?.slice(-5)}
                            </span>
                            <figure title="Copy">
                              <img src="/images/copy_icon.svg" alt="Icon" />
                            </figure>
                          </p>
                          {copied && (
                            <span style={{ color: "green" }}>
                              Copied to clipboard!
                            </span>
                          )}
                        </div>
                      ) : undefined}
                    </>
                  ) : undefined}
                  <Modal
                    open={openModal}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box className="modal_body">
                      <CloseIcon onClick={handleModalClose} className="close" />
                      <MetamaskModalContent />
                    </Box>
                  </Modal>
                </>
              ) : (
                <button
                  className="btn btn_primary"
                  onClick={() => navigate("/signin")}
                >
                  Sign in
                </button>
              )}
            </div>
          </nav>
        </div>
      </div>
      <LogoutModal
        openLogout={openLogout}
        setOpenLogout={setOpenLogout}
        handleLogout={handleLogout}
      />
      {isMobileModal ? (
        <Modal
          open={isMobileModal}
          onClose={() => setIsMobileModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="logout_modal"
        >
          <div className="modal_body">
            <CloseIcon
              onClick={() => setIsMobileModal(false)}
              className="close"
            />
            <div className="modal_content">
              <WebNotSupport />
            </div>
          </div>
        </Modal>
      ) : undefined}
    </>
  );
};

export default Header;
