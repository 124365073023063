import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import type { RootState } from "../app/store";
import {
  API_URL,
  STORAGE_KEYS,
  removeFromStorage,
} from "../helpers";
import { metaMaskAddress, resetAuth } from "../reducers/authSlice";
import { generateSEKAndHash } from "./crypto";

// Add new constant for available API endpoints
const API_ENDPOINTS = {
  USER: '/user',
  WALLET: '/wallet',
  // Add more endpoints as needed
} as const;

type ApiEndpoint = keyof typeof API_ENDPOINTS;

// Modified baseQuery to accept endpoint
const createBaseQuery = (endpoint: ApiEndpoint) => fetchBaseQuery({
  baseUrl: `${API_URL}${API_ENDPOINTS[endpoint]}`,
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).auth;
    const { tempToken } = (getState() as RootState).auth;

    const encryptData = generateSEKAndHash(token || tempToken || null);

    if (encryptData) {
      headers.set("hash", encryptData?.hash);
      headers.set("sek", encryptData?.sek);
    }
    headers.set("deviceType", "web");
    return headers;
  },
});

// Modified baseQueryWithAuth to accept endpoint
const createBaseQueryWithAuth = (endpoint: ApiEndpoint): BaseQueryFn<
  string | FetchArgs | any,
  unknown,
  FetchBaseQueryError
> => async (args, api, extraOptions) => {
  const result = await createBaseQuery(endpoint)(args, api, extraOptions);
  if (result.error?.status === 400) {
    const errors = Object.values(result?.error?.data || {});
    console.log({ errors });
    if (errors?.length > 1 && errors[1] === 400) {
      return result;
    }

    if (errors?.length) {
      const error = errors[0] as any;
      if (error?.length) {
        console.log(error[0]);
      }
    }
  }
  if (result.error?.status === 401) {
    removeFromStorage(STORAGE_KEYS.WalletAddress);
    api.dispatch(resetAuth());
    api.dispatch(
      metaMaskAddress({
        walletAddress: null,
      })
    );
    if (window) {
      window.location.replace("/");
    }
  }
  if (
    result.error &&
    result.error.status === 401 &&
    (api.getState() as RootState).auth.user
  ) {
    api.dispatch(resetAuth());
    api.dispatch(
      metaMaskAddress({
        walletAddress: null,
      })
    );
  }

  return result;
};

// Create separate APIs for different endpoints
export const createApiForEndpoint = (endpoint: ApiEndpoint) => createApi({
  baseQuery: createBaseQueryWithAuth(endpoint),
  tagTypes: ["UNAUTHORIZED", "UNKNOWN_ERROR"],
  endpoints: () => ({}),
});

// Export pre-configured APIs for common endpoints
export const userApi = createApiForEndpoint('USER');
export const walletApi = createApiForEndpoint('WALLET');

// You can still export default userApi for backward compatibility
export default userApi;
