/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  SideBar,
  PassiveNft,
  MintedNft,
  NftOnBid,
  Setting,
  Wallet,
  ManageSocialMedia,
  AddSocialMedia,
  ChangePassword,
  MyActivities,
  ClaimedCoupons,
  GiftsReceived,
} from "./features";
import Layout from "../../layout";
import "./account.scss";
import DatabaseNft from "./features/databaseNFT";
import { STORAGE_KEYS, getFromStorage } from "../../helpers";
import { useLazyGetSocialMediaListQuery } from "../../services/main/setting";

const AccountScreen = () => {
  const navigate = useNavigate();

  const [steps, setSteps] = useState(1);
  const [socialMediaHandles, setSocialMediaHandles] = useState(1);
  const [socialMedia, setSocialMedia] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState<any>();

  const [getSocialMediaMethod] = useLazyGetSocialMediaListQuery();

  const getActiveStep = async () => {
    const activeStep = await getFromStorage(STORAGE_KEYS.activeTab);
    if (activeStep) {
      setSteps(Number(activeStep));
    }
  };

  const getSocialMediaData = async () => {
    try {
      const res = await getSocialMediaMethod({}).unwrap();
      if (res?.statusCode === 200) {
        setSocialMedia(res?.data?.data || []);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getSocialMediaData();
    getActiveStep();
  }, []);

  const handleSteps = () => {
    switch (steps) {
      case 1:
        return <PassiveNft steps={steps} setSteps={setSteps} />;
      case 2:
        return <DatabaseNft steps={steps} setSteps={setSteps} />;
      case 3:
        return <MintedNft steps={steps} setSteps={setSteps} />;
      case 4:
        return (
          <>
            {socialMediaHandles === 1 ? (
              <ManageSocialMedia
                steps={steps}
                setSteps={setSteps}
                setSocialMediaHandles={setSocialMediaHandles}
                socialMedia={socialMedia}
                setSocialMedia={setSocialMedia}
                setSelectedMedia={setSelectedMedia}
                getSocialMediaData={getSocialMediaData}
              />
            ) : (
              <AddSocialMedia
                steps={steps}
                setSteps={setSteps}
                setSocialMediaHandles={setSocialMediaHandles}
                getSocialMediaData={getSocialMediaData}
                socialMediaHandles={socialMediaHandles}
                selectedMedia={selectedMedia}
              />
            )}
          </>
        );
      case 5:
        return <GiftsReceived steps={steps} setSteps={setSteps} />;
      case 6:
        return <NftOnBid steps={steps} setSteps={setSteps} />;
      case 7:
        return <ClaimedCoupons />;
      case 8:
        return <Wallet />;
      case 9:
        return <MyActivities steps={steps} setSteps={setSteps} />;
      case 10:
        return <ChangePassword steps={steps} setSteps={setSteps} />;
      case 11:
        return (
          <Setting
            steps={steps}
            setSteps={setSteps}
            setSocialMediaHandles={setSocialMediaHandles}
            socialMedia={socialMedia}
            setSocialMedia={setSocialMedia}
            setSelectedMedia={setSelectedMedia}
            getSocialMediaData={getSocialMediaData}
            socialMediaHandles={socialMediaHandles}
            selectedMedia={selectedMedia}
          />
        );
      default:
        return <div>Default content</div>;
    }
  };

  let accountLabel;
  switch (steps) {
    case 1:
      accountLabel = "My Passive Assets";
      break;
    case 2:
      accountLabel = "My Database Assets";
      break;
    case 3:
      accountLabel = "My Minted Assets";
      break;
    case 4:
      accountLabel =
        socialMediaHandles === 1 ? "Manage Social Media" : "Add Social Media";
      break;
    case 5:
      accountLabel = "Gifts Received";
      break;
    case 6:
      accountLabel = "Asset On Bid";
      break;
    case 7:
      accountLabel = "Claimed Coupons";
      break;
    case 8:
      accountLabel = "My Wallet";
      break;
    case 9:
      accountLabel = "My Activities";
      break;
    case 10:
      accountLabel = "Change Password";
      break;
    case 11:
      accountLabel = "Personal Detail";
      break;
    default:
      accountLabel = "Account";
  }

  return (
    <Layout>
      <main className="page_account">
        <section className="site_pagination uht_spc">
          <div className="conta_iner">
            <ul>
              <li>
                <a href="javascript:void(0)" onClick={() => navigate("/")}>
                  Home
                </a>
              </li>
              <li>{accountLabel}</li>
            </ul>
          </div>
        </section>

        <section className="account_sc uhb_spc">
          <div className="conta_iner">
            <div className="gap_p">
              <div className="left_s">
                <SideBar steps={steps} setSteps={setSteps} />
              </div>
              <div className="right_s">
                <div className="account_detail">{handleSteps()}</div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default AccountScreen;
