import { END_POINTS } from "../../helpers";
import { User } from "../../types/User";
import emptySplitApi from "../../utils/rtk";
import { CommonResponseType } from "../cms";

export enum CouponStatus {
  REDEEMED = 1,
  RESERVED = 2,
  AVAILABLE = 3
}

export enum CouponType {
  INDIRECT = 1,
  DIRECT = 2
}

export enum DiscountType {
  FIXED = 1,
  PERCENTAGE = 2
}

export interface CouponData {
  quantity: number;
  assignedTo: User[];
  code: string;
  couponType: CouponType;
  quantityLeft: number;
  nftCount: number;
  createdAt: string;
  createdBy: number;
  creator: string;
  description: string;
  discountType: DiscountType;
  effectiveValue: number;
  endDate: string;
  image: string;
  isBlocked: boolean;
  isClaimed: boolean;
  isDeleted: boolean;
  isExpire: boolean;
  startDate: string;
  status: CouponStatus;
  _id: string;
  price: string;
  sellPrice: string;
  companyName: string;
}

interface CouponResponseType extends CommonResponseType {
  data: CouponData[];
}

export const couponApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCoupon: builder.query<CouponResponseType, void>({
      query: () => ({
        url: END_POINTS.getCoupon,
        method: "GET",
      }),
    }),
    buyCoupon: builder.mutation<CouponResponseType, string>({
      query: (id) => ({
        url: END_POINTS.buyCoupon + "/" + id,
        method: "POST",
      }),
    }),
    redeemCoupon: builder.mutation<CouponResponseType, string>({
      query: (couponCode) => ({
        url: END_POINTS.redeemCoupon + "/" + couponCode,
        method: "POST",
      }),
    }),
  })
})

export const {useGetCouponQuery, useBuyCouponMutation, useRedeemCouponMutation} = couponApi;
