/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, CardContent, Grid, Modal, TextField, Box, Typography, Snackbar, Alert } from "@mui/material";
import { useState, useMemo, useEffect } from "react";
import { CouponData, CouponType, useBuyCouponMutation, useGetCouponQuery } from "../../services/admin/coupon";
import CouponFilters from "./couponFilters";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useLazyGetMyWalletQuery } from "../../services/main/setting";
import { MyWallet } from "../../types/General";

const Coupons = () => {
  const user = useAuth();
  const navigate = useNavigate();
  
  const [selectedOrg, setSelectedOrg] = useState<string>("all");
  const [selectedType, setSelectedType] = useState<string>("all");
  const [sortBy, setSortBy] = useState<string>("price");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [openModal, setOpenModal] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState<CouponData | null>(null);
  const [description, setDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [myWallet] = useLazyGetMyWalletQuery();
  const [walletData, setWalletData] = useState<MyWallet>();

  const getMyWallet = async () => {
    try {
      const result = await myWallet({}).unwrap();
      if (result?.statusCode === 200) {
        setWalletData(result?.data || []);
      }
    } catch (error) {
      console.error('Error fetching wallet data:', error);
    }
  };

  useEffect(() => {
    getMyWallet();
  }, [user]);

  useEffect(() => {
    const handleWalletUpdate = () => {
      getMyWallet();
    };

    window.addEventListener('WALLET_UPDATED', handleWalletUpdate);

    return () => {
      window.removeEventListener('WALLET_UPDATED', handleWalletUpdate);
    };
  }, []);

  const {data, refetch} = useGetCouponQuery(undefined, {
    selectFromResult: ({data}) => ({data: data?.data ?? []})
  });

  const organizations = useMemo(() => {
    return Array.from(new Set(data?.map(item => item.assignedTo[0]._id)));
  }, [data]);

  const groupedCoupons = useMemo(() => {
    let filteredData = [...(data || [])];
    
    if (selectedOrg !== "all") {
      filteredData = filteredData.filter(item => item.assignedTo[0]._id === selectedOrg);
    }

    if (selectedType !== "all") {
      filteredData = filteredData.filter(item => item.couponType === parseInt(selectedType));
    }

    const grouped = filteredData.reduce((acc, item) => {
      const orgId = item.assignedTo[0]._id;
      const price = item.effectiveValue;
      const discountType = item.discountType;
      const groupKey = `${orgId}-${price}-${discountType}`;
      
      if (!acc[groupKey]) {
        acc[groupKey] = {
          ...item,
          quantity: 1
        };
      } else {
        acc[groupKey].quantity += 1;
      }
      return acc;
    }, {} as Record<string, CouponData & { quantity: number }>);
  
    let sortedCoupons = Object.values(grouped);
    sortedCoupons.sort((a, b) => {
      const valueA = sortBy === "price" ? a.effectiveValue : a.quantity;
      const valueB = sortBy === "price" ? b.effectiveValue : b.quantity;
      return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
    });

    return sortedCoupons;
  }, [data, selectedOrg, selectedType, sortBy, sortOrder]);

  const clearFilters = () => {
    setSelectedOrg("all");
    setSelectedType("all");
    setSortBy("price");
    setSortOrder("asc");
  };

  const isFiltersActive = useMemo(() => {
    return selectedOrg !== "all" || 
           selectedType !== "all" || 
           sortBy !== "price" || 
           sortOrder !== "asc";
  }, [selectedOrg, selectedType, sortBy, sortOrder]);

  const [buyCoupon] = useBuyCouponMutation();

  const handleBuyCoupon = async (id: string) => {
    try {
      const response = await buyCoupon(id.toString());
      if ('error' in response) {
        const errorMsg = (response.error as any)?.data?.message || 'Failed to buy coupon';
        setErrorMessage(errorMsg);
      } else {
        setErrorMessage("");
        refetch();
        window.dispatchEvent(new Event('WALLET_UPDATED'));
      }
    } catch (error) {
      const errorMsg = error instanceof Error ? error.message : 'An unexpected error occurred';
      setErrorMessage(errorMsg);
      console.error('Error buying coupon: ', error);
    }
  };

  const handleBuyCouponClick = (item: CouponData) => {
    if (user) {
      setSelectedCoupon(item);
      setOpenModal(true);
    } else {
      setShowLoginModal(true);
    }
  };

  const handleLoginConfirm = () => {
    setShowLoginModal(false);
    navigate('/signin');
  };

  const handleSubmitBuy = async () => {
    if (selectedCoupon) {
      if (selectedCoupon.couponType === CouponType.DIRECT && !description.trim()) {
        return;
      }
      await handleBuyCoupon(selectedCoupon._id);
      setOpenModal(false);
      setDescription("");
      setSelectedCoupon(null);
    }
  };

  return (
    <>
      <CouponFilters
        selectedOrg={selectedOrg}
        selectedType={selectedType}
        sortBy={sortBy}
        sortOrder={sortOrder}
        organizations={organizations}
        data={data}
        onOrgChange={setSelectedOrg}
        onTypeChange={setSelectedType}
        onSortByChange={setSortBy}
        onSortOrderChange={setSortOrder}
        onClearFilters={clearFilters}
        showClearFilters={isFiltersActive}
      />

      {user && (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'flex-end', 
          padding: 2, 
          alignItems: 'center'
        }}>
          <Typography 
            variant="h6" 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              backgroundColor: '#f5f5f5',
              padding: '8px 16px',
              borderRadius: '8px',
              color: '#2196f3'
            }}
          >
            <span>Available Points:</span>
            <strong>I2E Points: {walletData?.I2E_Balance || 0}</strong>
            <strong>Carbon Credits: {walletData?.carbonCredits || 0}</strong>
          </Typography>
        </Box>
      )}

      <Grid container spacing={2} sx={{ padding: 2 }}>
        {groupedCoupons?.map((item) => (
          <Grid item xs={12} sm={6} md={3} key={item._id}>
            <Card>
              <CardContent className="coupon_card">
                <div className="coupon_card_dtl">
                  <figure>
                    <span>Gift Voucher</span>
                    <img src="/images/bar_code.svg" alt="" />
                  </figure>
                  <div className="coupon_dtl">
                    <figure>
                      <img src={item.assignedTo[0].image} alt="logo" />
                    </figure>
                  </div>
                  <div className="coupon_btn">
                    <button className="btn btn_primary">
                      {item?.discountType === 1 ? "AED " : ""}
                      {item?.effectiveValue || "0"}{" "}
                      {item?.discountType === 2 ? "%" : ""}
                    </button>
                  </div>
                </div>
                <div className="add_to_cart">
                  <p className="quantity">Quantity: <span>{item.quantity}</span></p>
                  <Button 
                    className="btn btn_primary" 
                    onClick={() => handleBuyCouponClick(item)}
                  >
                    Buy
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="coupon-modal-title"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}>
          <Typography id="coupon-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
            {selectedCoupon?.couponType === CouponType.DIRECT ? 'Please Provide Input' : 'Coupon Description'}
          </Typography>
          
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 3,
            p: 2,
            backgroundColor: '#f5f5f5',
            borderRadius: '8px',
          }}>
            <Typography 
              variant="h4" 
              sx={{
                fontWeight: 'bold',
                color: '#2196f3',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {selectedCoupon?.discountType === 1 && "AED "}
              {selectedCoupon?.effectiveValue || "0"}
              {selectedCoupon?.discountType === 2 && "%"}
            </Typography>
          </Box>

          <Typography sx={{ mb: 2 }}>
            {selectedCoupon?.description}
          </Typography>
          
          {selectedCoupon?.couponType === CouponType.DIRECT && (
            <TextField
              fullWidth
              inputProps={{
                maxLength: 15,
                pattern: '[0-9]*'
              }}
              placeholder="Enter phone number"
              value={description}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, '');
                setDescription(value);
              }}
              sx={{ mb: 2 }}
            />
          )}
          
          <Button 
            className="btn btn_primary"
            onClick={handleSubmitBuy}
            disabled={selectedCoupon?.couponType === CouponType.DIRECT && 
              (!description.trim() || description.length < 8)}
            fullWidth
          >
            Submit
          </Button>
        </Box>
      </Modal>

      <Modal
        open={showLoginModal}
        onClose={() => setShowLoginModal(false)}
        aria-labelledby="login-modal-title"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: '16px',
        }}>
          <Typography 
            id="login-modal-title" 
            variant="h5" 
            component="h2" 
            sx={{ 
              mb: 2,
              fontWeight: 500,
              color: '#2D3436',
              textAlign: 'center'
            }}
          >
            Please Log In
          </Typography>
          <Typography sx={{ 
            mb: 4,
            color: '#636E72',
            textAlign: 'center',
            fontSize: '1rem'
          }}>
            You need to be logged in to buy coupons!
          </Typography>
          <Box sx={{ 
            display: 'flex', 
            gap: 2, 
            justifyContent: 'center'
          }}>
            <Button 
              onClick={() => setShowLoginModal(false)}
              sx={{
                width: '160px',
                padding: '12px 24px',
                border: '1px solid #7AB893',
                color: '#56BB6D',
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '1rem',
                '&:hover': {
                  backgroundColor: 'rgba(122, 184, 147, 0.2)',
                  border: '1px solid #7AB893',
                }
              }}
            >
              CANCEL
            </Button>
            <Button 
              onClick={handleLoginConfirm}
              sx={{
                width: '160px',
                padding: '12px 24px',
                backgroundColor: '#56BB6D',
                color: 'white',
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '1rem',
                '&:hover': {
                  backgroundColor: '#081630',
                  border: '2px solid #56BB6D',
                }
              }}
            >
              SIGN IN
            </Button>
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setErrorMessage("")} 
          severity="error" 
          sx={{ width: '100%' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Coupons;
